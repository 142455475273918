@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  padding: 0 0 6.4rem;
  color: v.$white;
  background: v.$gradGreenBg02;

  @include m.sp {
    padding: 0 0 2rem;
  }

  &_mask {
    width: 100%;
    height: 7.5vw;
    margin-bottom: -1px;
    background: v.$gradGreenBg02;
    mask-image: url('../img/bg_footer.svg');
    mask-repeat: no-repeat;
    mask-position: left bottom;
    mask-size: 100%;

    @include m.tab {
      height: 10.4rem;
    }

    @include m.sp {
      height: 5.5rem;
      mask-image: url('../img/bg_footer_sp.svg');
      mask-size: 100%;
    }
  }

  &Logo {
    width: 18.1rem;

    @include m.sp {
      width: 12rem;
    }
  }

  &Info {
    padding-top: 1.6rem;

    &_text {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1.625;
      letter-spacing: 0.08em;

      @include m.sp {
        font-size: 1.3rem;
      }
    }
  }

  &Copy {
    padding-top: 6.4rem;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.08em;

    @include m.sp {
      padding-top: 3.2rem;
      font-size: 1.2rem;
    }
  }
}
