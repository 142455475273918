@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-service {
  margin-top: 16rem;
  padding: 16rem 0 15.2rem;
  position: relative;
  background-color: v.$greenBg;
  border-top-left-radius: 46.7vw 16.5vw;

  @include m.sp {
    margin-top: 8rem;
    padding: 8rem 0;
  }

  &Media {
    padding-top: 7.2rem;
    display: flex;
    gap: 8.8rem;

    @include m.pc {
      justify-content: space-between;
      align-items: center;
    }

    @include m.sp {
      padding-top: 4.8rem;
      flex-flow: column;
      gap: 3.2rem;
    }

    & + & {
      padding-top: 8rem;

      @include m.sp {
        padding-top: 4rem;
      }
    }

    &_img {
      position: relative;
      z-index: 1;

      @include m.pc {
        width: 48.1rem;
      }

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: v.$gradGreenBg01;
        position: absolute;
        top: 2rem;
        left: 2rem;
        z-index: -1;
        border-radius: 10px;

        @include m.sp {
          top: 1rem;
          left: 1rem;
        }
      }
    }

    &_box {
      @include m.pc {
        flex: 1;
      }
    }

    &_heading {
      font-size: 2.6rem;
      font-weight: 500;
      line-height: 1.81;
      letter-spacing: 0.08em;
      color: v.$green;

      @include m.sp {
        font-size: 2rem;
      }
    }

    &_text {
      padding-top: 1.6rem;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.125;
      letter-spacing: 0.08em;

      @include m.sp {
        padding-top: 1.2rem;
        font-size: 1.4rem;
      }
    }
  }
}
