@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-news {
  padding: 15.6rem 0 18rem;
  overflow: hidden;
  background-color: v.$grayBg;
  position: relative;
  z-index: 1;
  border-bottom-right-radius: 46.5% 16.5vw;

  @include m.sp {
    padding: 8rem 0;
  }

  &Cont {
    @include m.pc {
      display: flex;
      justify-content: space-between;
      gap: 6rem;
    }

    &_intro {
      @include m.pc {
        flex: 1;
      }
    }

    &_main {
      @include m.pc {
        width: 69.8rem;
      }

      @include m.sp {
        padding-top: 4.8rem;
      }
    }
  }

  &List {
    @include m.sp {
      padding-top: 4rem;
    }

    &_item {
      & + & {
        margin-top: 2rem;
      }
    }

    &_link {
      width: 100%;
      padding: 3.2rem 8rem 2.4rem 3.2rem;
      display: block;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      overflow: hidden;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:before {
        background-color: v.$white;
        z-index: -2;
      }

      &:after {
        background: v.$gradGreenBg01;
        z-index: -1;
        opacity: 0;
      }

      @include m.pc {
        transition: background v.$anime;

        &:after {
          transition: opacity v.$anime;
        }

        .p-newsList {
          &_date,
          &_heading {
            transition: color v.$anime;
          }
        }

        &:any-link {
          &:hover {
            background: v.$gradGreenBg01;

            &:after {
              opacity: 1;
            }

            .p-newsList {
              &_inner {
                &:after {
                  background: url('../img/ico_external_white.svg') no-repeat center;
                  background-size: contain;
                }
              }

              &_date,
              &_heading {
                color: v.$white;
              }
            }
          }
        }
      }

      @include m.sp {
        padding: 2rem 4rem 1.6rem 2rem;
      }

      &.is-external {
        .p-newsList_inner {
          &:after {
            content: '';
            width: 2rem;
            height: 2rem;
            background: url('../img/ico_external.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: calc(100% + 3rem);
            translate: 0 -50%;
            z-index: 2;

            @include m.sp {
              width: 1.6rem;
              height: 1.6rem;
              left: calc(100% + 1rem);
            }
          }
        }
      }
    }

    &_inner {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    &_date {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.08em;
      color: v.$gray;

      @include m.sp {
        font-size: 1.2rem;
      }
    }

    &_heading {
      padding-top: 1.6rem;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2.1;
      letter-spacing: 0.08em;

      @include m.sp {
        padding-top: 0.8rem;
        font-size: 1.4rem;
      }
    }
  }
}
