@use 'global/mixin' as m;
@use 'global/variable' as v;

u-color {
  &_text {
    &--white {
      color: v.$white;
    }
    &--black {
      color: v.$black;

      &Main {
        color: v.$blackMain;
      }
    }
    &--green {
      color: v.$green;
    }
  }

  &_bg {
    &--white {
      background-color: v.$white;
    }
    &--black {
      background-color: v.$black;

      &Main {
        background-color: v.$blackMain;
      }
    }
    &--green {
      background-color: v.$green;
    }
  }
}
