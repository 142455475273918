@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-anime {
  &View {
    position: relative;
    z-index: 1;
    opacity: 0;
    translate: -10px 0;
    transition: opacity v.$anime, translate v.$anime;

    &.is-anime {
      translate: 0 0;
      opacity: 1;
    }
  }
}
