@charset "UTF-8";

/* animation */
$anime: 0.3s ease-out;
/* end animation */

/* color */
// white
$white: #fff;

// black
$black: #000;
$blackMain: #1e1e1e;

// gray
$gray: #838383;
$grayBg: #f7f7f7;
$grayForm: #f5f5f5;
$grayBorder: #eaeaea;
$grayScrollBar: #e8e8e8;

// red
$red: #e61c24;

// green
$green: #7bb552;
$greenBg: #ecf1ec;

// gradient
$gradGreenBg01: linear-gradient(90deg, rgba(196, 210, 113, 1) 0%, rgba(127, 184, 82, 1) 99.8%);
$gradGreenBg02: linear-gradient(90deg, rgba(127, 186, 82, 1) 0.2%, rgba(198, 212, 113, 1) 100%);
$gradGrayBg01: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(248, 248, 248, 1) 34.76%, rgba(238, 238, 238, 1) 100%);
$gradCircle: linear-gradient(41.54deg, rgba(127, 186, 82, 1) 0.2%, rgba(198, 212, 113, 1) 100%);
/* end color */

/* font */
$notoSans: 'Noto Sans JP';
$mplus: 'M PLUS Rounded 1c';
/* end font */
