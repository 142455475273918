@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-text {
  $this: &;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.89;
  letter-spacing: 0.08em;

  @include m.sp {
    font-size: 1.4rem;
  }

  &--normal {
    @extend #{$this};
    font-weight: 400;
  }

  &--medium {
    @extend #{$this};
    font-weight: 500;
  }

  &--bold {
    @extend #{$this};
    font-weight: bold;
  }
}
