@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  width: 100%;
  margin: 0 auto;

  @include m.pc {
    max-width: 1040px;
    padding: 0 20px;
  }

  @include m.sp {
    width: 90%;
  }
}
