@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  font-size: 62.5%;

  @include m.tab {
    font-size: calc(100vw / 123);
  }
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  font-family: v.$notoSans, sans-serif;
  color: v.$blackMain;
  background-color: v.$white;

  &.is-fixed {
    @include m.sp {
      overflow: hidden;
    }
  }
}

main {
  display: block;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 100vh;
    display: block;
    background: v.$gradGrayBg01;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
  }
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}

/* reCAPTCHA表示設定 */
.grecaptcha-badge {
  opacity: 0;
  transition: opacity v.$anime, right v.$anime 0s !important;

  @include m.sp {
    bottom: 11rem !important;
  }

  &.is-animated {
    opacity: 1;
  }
}
/* end reCAPTCHA表示設定 */
