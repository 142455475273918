@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  width: 100%;
  padding: 5.2rem 2rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: padding v.$anime;

  @include m.sp {
    padding: 2rem 2.5rem;
    transition: background-color 0.1s ease;
  }

  &.is-thanks {
    transition: none;

    .l-headerCont {
      opacity: 1 !important;
    }
  }

  &.is-scrolled {
    background-color: rgba(v.$white, 0.7);

    @include m.pc {
      padding: 2rem;
    }

    .l-headerCont {
      opacity: 1 !important;
      transition: none !important;
    }
  }

  &Cont {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.pc {
      max-width: 1244px;
    }

    &_link {
      width: 18.1rem;
      display: block;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include m.sp {
        width: 12rem;
      }
    }

    &_nav {
      @include m.sp {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: -1.6rem;
        left: -2.4rem;
        height: 100dvh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(v.$white, 0.9);
        transition: opacity v.$anime;
      }

      &.is-view {
        @include m.sp {
          opacity: 1;
          pointer-events: auto;
        }

        .l-headerNav_list {
          @include m.sp {
            translate: 0 0;
            opacity: 1;
          }
        }
      }
    }
  }

  &Nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5.6rem;

    @include m.sp {
      flex-flow: column;
      justify-content: center;
      gap: 4rem;
    }

    &_list {
      @include m.sp {
        translate: -10px 0;
        opacity: 0;
      }

      @for $i from 1 through 5 {
        @include m.sp {
          &:nth-of-type(#{$i}) {
            transition: translate v.$anime $i * 0.1s, opacity v.$anime $i * 0.1s;
          }
        }
      }
    }

    &_link {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 0.08em;

      @include m.pc {
        transition: color v.$anime;

        &:hover {
          color: v.$green;
        }
      }
    }
  }

  &Hamburger {
    @include m.pc {
      display: none;
    }

    @include m.sp {
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      position: fixed;
      bottom: 1rem;
      right: 2.4rem;
      translate: 0 -50%;
      z-index: 10;

      &:before,
      &:after {
        content: '';
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }

      &:before {
        width: calc(100% + 0.2rem);
        height: calc(100% + 0.2rem);
        background: v.$gradGreenBg02;
        z-index: -2;
      }

      &:after {
        width: 98%;
        height: 98%;
        background-color: v.$white;
        z-index: -1;
      }
    }

    &.is-active {
      animation: none;
      z-index: 100;

      .l-headerHamburger {
        &_inner {
          transition: rotate v.$anime;
          rotate: 0deg;
        }
        &_line {
          &--top {
            width: 100%;
            rotate: -45deg;
            top: 50%;
            translate: 0 -50%;
            transform-origin: center;
            transition: all v.$anime;
          }
          &--mid {
            opacity: 0;
            transition: opacity v.$anime;
          }
          &--btm {
            width: 100%;
            rotate: 45deg;
            top: 50%;
            translate: 0 -50%;
            transform-origin: center;
            transition: all v.$anime;
          }
        }
      }
    }

    &.is-close {
      .l-headerHamburger {
        &_inner {
          animation: hamburgerLineReverse 0.2s forwards;
        }
        &_line {
          &--top {
            animation: hamburgerLineTopReverse 0.4s forwards;
          }
          &--mid {
            animation: hamrurgerLineMidReverse 0.4s forwards;
          }
          &--btm {
            animation: hamburgerLineBtmReverse 0.4s forwards;
          }
        }
      }
    }

    @keyframes hamburgerLineReverse {
      0% {
        rotate: 0deg;
      }
      100% {
        rotate: -15deg;
      }
    }

    @keyframes hamburgerLineTopReverse {
      0% {
        width: 100%;
        top: 50%;
        left: 50%;
        rotate: -45deg;
        translate: -50% -50%;
        transform-origin: center;
      }
      50% {
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        rotate: 0deg;
        transform-origin: center;
      }
      51% {
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        transform-origin: center;
      }
      100% {
        width: 50%;
        transform-origin: center;
      }
    }

    @keyframes hamrurgerLineMidReverse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes hamburgerLineBtmReverse {
      0% {
        width: 100%;
        top: 50%;
        left: 50%;
        rotate: 45deg;
        translate: -50% -50%;
        transform-origin: center;
      }
      50% {
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        rotate: 0deg;
        transform-origin: center;
      }
      51% {
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        transform-origin: center;
      }
      100% {
        width: 50%;
        transform-origin: center;
      }
    }

    &_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -3;
      translate: -50% -50%;
      animation: 3s circleAnime forwards infinite;

      &:before,
      &:after {
        content: '';
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
      }

      &:before {
        width: calc(100% + 0.2rem);
        height: calc(100% + 0.2rem);
        background: v.$gradGreenBg02;
        z-index: -2;
      }

      &:after {
        width: 98%;
        height: 98%;
        background-color: v.$white;
        z-index: -1;
      }
    }

    @keyframes circleAnime {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1.4);
        opacity: 0;
      }
    }

    &_inner {
      width: 2rem;
      height: 1.2rem;
      rotate: -15deg;
    }

    &_line {
      $this: &;
      width: 100%;
      height: 0.2rem;
      background: v.$gradGreenBg01;
      border-radius: 0.5rem;
      position: absolute;

      &--top {
        @extend #{$this};
        top: 0;
        transform-origin: right;
        width: 50%;
        left: auto;
        right: 0;
      }
      &--mid {
        @extend #{$this};
        width: 75%;
        top: 50%;
        left: 50%;
        translate: -50% 0;
      }
      &--btm {
        @extend #{$this};
        width: 55%;
        top: 100%;
        left: 0;
      }
    }
  }
}
