@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-about {
  padding: 15.4rem 0 14.4rem;

  @include m.sp {
    padding: 8rem 0;
  }

  &Cont {
    padding-top: 7.2rem;

    @include m.sp {
      padding-top: 4.8rem;
    }

    &_inner {
      @include m.pc {
        display: flex;
        flex-wrap: wrap;
        gap: 3.4rem;
      }

      & + & {
        padding-top: 4rem;

        @include m.sp {
          padding-top: 3.2rem;
        }
      }

      &Box {
        @include m.pc {
          width: 48%;
        }

        @include m.sp {
          width: 100%;
        }

        & + & {
          @include m.sp {
            padding-top: 3.2rem;
          }
        }
      }
    }
  }

  &Unit {
    width: 100%;

    &_head,
    &_body {
      padding: 2rem 1rem;
      font-size: 1.8rem;

      @include m.sp {
        padding: 1.6rem 1rem;
        font-size: 1.6rem;
      }
    }

    &_head {
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.08em;
      border-top: 1px solid v.$green;
      border-bottom: 1px dotted v.$green;

      @include m.sp {
        border-bottom: 2px dotted v.$green;
      }
    }

    &_body {
      line-height: 1.89;
      font-weight: 300;
    }
  }
}
