@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-hero {
  padding-top: 18.4rem;
  position: relative;
  z-index: 1;

  @include m.sp {
    padding-top: 10.2rem;
  }

  &Bg {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    // transition: filter v.$anime, opacity v.$anime;

    &.is-scrolled {
      // filter: blur(2px);
      // opacity: 0.95;
    }
  }

  &Line {
    height: 12.3rem;
    position: absolute;
    z-index: -1;
    translate: -50% -50%;
    rotate: -40deg;

    @include m.sp {
      height: 4.5rem;
    }

    &--type {
      &01 {
        width: 57.5rem;
        top: 75.2%;
        left: 30%;

        @include m.sp {
          width: 21rem;
          top: 58.2%;
          left: 15%;
        }
      }

      &02 {
        width: 49.1rem;
        top: 7%;
        left: 23.3%;

        @include m.sp {
          width: 21rem;
          top: 9.8%;
          left: 15.3%;
        }
      }

      &03 {
        width: 44.5rem;
        top: 37.7%;
        left: 91.5%;

        @include m.sp {
          width: 21rem;
          top: 31.4%;
          left: 89.5%;
        }
      }
    }

    &_white,
    &_greenInner {
      border-radius: 100px;
    }

    &_white {
      height: 100%;
      background-color: v.$white;
      transform-origin: left;
      position: absolute;
      top: 0;
      left: 0;

      &--type {
        &01 {
          width: 100%;
          animation: whiteMove 3s infinite forwards;
        }

        &02 {
          width: 100%;
          animation: whiteMove 3s infinite 0.5s forwards;
        }

        &03 {
          width: 100%;
          animation: whiteMove 3s infinite 0.25s forwards;
        }
      }
    }

    &_green {
      height: 5.6rem;
      position: absolute;
      translate: -50% -50%;

      @include m.sp {
        height: 2.8rem;
      }

      &Inner {
        width: 100%;
        height: 100%;
        background: v.$gradGreenBg02;
        transform-origin: left;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      &--type {
        &01 {
          width: 30.2rem;
          top: 100%;
          left: 50%;

          @include m.sp {
            width: 12.2rem;
            top: 90%;
            left: 0%;
          }

          .p-heroLine_greenInner {
            animation: greenMove 3s infinite 0.3s forwards;
          }
        }

        &02 {
          width: 37.4rem;
          top: 90%;
          left: 0%;

          @include m.sp {
            width: 12.2rem;
          }

          .p-heroLine_greenInner {
            animation: greenMove 3s infinite 0.6s forwards;
          }
        }

        &03 {
          width: 30.2rem;
          top: 90%;
          left: 71%;

          @include m.sp {
            width: 12.2rem;
            top: 110%;
            left: 57%;
          }

          .p-heroLine_greenInner {
            animation: greenMove 3s infinite forwards;
          }
        }
      }
    }
  }

  @keyframes whiteMove {
    0% {
      width: 0%;
      left: 0;
      transform-origin: left;
    }
    33% {
      width: 100%;
      left: 0;
      transform-origin: left;
    }
    66% {
      width: 100%;
      right: 0;
      left: auto;
      transform-origin: right;
      opacity: 1;
    }
    100% {
      width: 0%;
      right: 0;
      left: auto;
      transform-origin: right;
      opacity: 0;
    }
  }

  @keyframes greenMove {
    0% {
      width: 0%;
      left: 0;
      transform-origin: left;
    }
    33% {
      width: 100%;
      left: 0;
      transform-origin: left;
    }
    66% {
      width: 100%;
      right: 0;
      left: auto;
      transform-origin: right;
      opacity: 1;
    }
    100% {
      width: 0%;
      right: 0;
      left: auto;
      transform-origin: right;
      opacity: 0;
    }
  }

  &Main {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include m.pc {
      height: 426px;
    }

    @include m.sp {
      flex-flow: column-reverse;
    }
  }

  &Intro {
    @include m.sp {
      padding-top: 2.4rem;
      text-align: center;
    }
    &_heading {
      font-size: 4.6rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.1em;

      @include m.sp {
        margin-right: -0.8em;
        font-size: 2.8rem;
        letter-spacing: 0.05em;
        line-height: 1.6;
      }
    }

    &_text {
      padding-top: 3.2rem;
      font-family: v.$mplus;
      font-size: 3rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.1em;
      color: v.$green;

      @include m.sp {
        padding-top: 1.3rem;
        padding-top: 1.2rem;
        font-size: 2rem;
      }
    }
  }

  &Logo {
    width: 42.3rem;
    height: 42.3rem;
    margin: 0 auto;
    position: relative;
    // left: 50%;
    transition: translate 1s ease 2s, margin 1s ease 2s;

    @include m.pc {
      translate: -50% 0;
      // translate: -50% 0;
      // position: absolute;
      // z-index: 1;
      // left: 50%;
    }

    @include m.sp {
      width: 19rem;
      height: 19rem;
    }

    &_view {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      // img {
      //   opacity: 0;
      //   transition: opacity 0.6s ease-out 3s;
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
      }

      &:before {
        background: url('../img/hero_img.svg') no-repeat center;
        background-size: contain;
        z-index: -2;
        opacity: 0;
        transition: opacity 0.1s ease 2.9s;
      }

      &:after {
        content: '';
        background: url('../img/hero_img_white.svg') no-repeat center;
        background-size: contain;
        transition: opacity 1s ease-out 2.9s;
        z-index: -1;
      }
    }

    // svg {
    //   width: 100%;
    //   height: 100%;

    //   image {
    //     transition: width 0.3s ease-out, height 0.3s ease-out;
    //   }

    //   .rect {
    //     // width: 32%;
    //     // height: 32%;
    //     width: 27%;
    //     height: 27%;
    //     fill: rgba(128, 193, 82, 1);
    //     z-index: -1;
    //     // clip-path: url(#clip01);
    //     -webkit-stroke-linecap: round;
    //     -webkit-stroke-linejoin: round;
    //     stroke-linecap: round;
    //     stroke-linejoin: round;
    //     rx: 50%;
    //     ry: 50%;
    //     x: 0%;
    //     y: 70%;
    //     border-radius: 50%;
    //     opacity: 0;
    //     animation: rectMoveReverse 1s ease 0s 1 normal forwards;
    //   }
    // }

    &_mask {
      width: 100%;
      height: 100%;
    }

    &.is-move {
      translate: 0 0;
      // transition: translate 1s ease 2s;

      .p-heroLogo_view {
        img {
          opacity: 1;
        }

        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 0;
        }
      }

      .rect {
        animation: rectMove 1.5s ease 0s 1 normal forwards;
      }

      .p-heroCircle_item {
        animation: rectMove 1.5s ease 0s 1 normal forwards;
      }
    }
  }

  &Circle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    clip-path: url(#clip01);
    // background-color: v.$black;

    &_item {
      width: 28%;
      height: 28%;
      background: v.$gradCircle;
      position: absolute;
      top: 86.5%;
      left: 13.5%;
      translate: -49% -50%;
      border-radius: 50%;
      z-index: 3;
      opacity: 0;
    }
  }

  @keyframes rectMove {
    0% {
      // width: 27%;
      // height: 27%;
      // rx: 50%;
      // ry: 50%;
      // x: 0%;
      // y: 70%;
      // opacity: 0.7;
      opacity: 0.3;
      top: 86.5%;
      left: 13.5%;
    }
    // 13% {
    //   width: 27%;
    //   // height: 70%;
    //   height: 27%;
    //   rx: 27%;
    //   ry: 27%;
    //   x: 0%;
    //   y: 0%;
    //   opacity: 0.7;
    // }
    26% {
      // width: 27%;
      // height: 27%;
      // border-radius: 50%;
      // rx: 50%;
      // ry: 50%;
      // x: 0%;
      // y: 0%;
      opacity: 1;
      // opacity: 0.7;
      top: 13.5%;
      left: 13.5%;
    }
    // 39% {
    //   // width: 100%;
    //   width: 27%;
    //   height: 27%;
    //   rx: 27%;
    //   ry: 27%;
    //   x: 0%;
    //   y: 0%;
    //   opacity: 0.7;
    // }
    52% {
      // width: 27%;
      // height: 27%;
      // rx: 50%;
      // ry: 50%;
      // x: 68%;
      // y: 0%;
      opacity: 1;
      // opacity: 0.7;
      top: 13.5%;
      left: 86.5%;
    }
    // 58.5% {
    //   width: 27%;
    //   // height: 65%;
    //   height: 27%;
    //   rx: 27%;
    //   ry: 27%;
    //   x: 68%;
    //   y: 0%;
    //   opacity: 0.7;
    // }
    65% {
      // width: 27%;
      // height: 27%;
      // rx: 50%;
      // ry: 50%;
      // x: 68%;
      // y: 35%;
      opacity: 1;
      // opacity: 0.7;
      top: 50%;
      left: 86.5%;
    }
    // 71.5% {
    //   // width: 70%;
    //   width: 27%;
    //   height: 27%;
    //   rx: 27%;
    //   ry: 27%;
    //   x: 30%;
    //   y: 35%;
    //   opacity: 0.7;
    // }
    78% {
      // width: 27%;
      // height: 27%;
      // rx: 50%;
      // ry: 50%;
      // x: 35%;
      // y: 35%;
      opacity: 1;
      // opacity: 0.7;
      top: 50%;
      left: 50%;
    }
    // 89% {
    //   width: 27%;
    //   height: 27%;
    // }
    98% {
      // width: 27%;
      // height: 27%;
      // rx: 50%;
      // ry: 50%;
      // x: 70%;
      // y: 70%;
      top: 86.5%;
      left: 86.5%;
    }
    100% {
      opacity: 0;
    }
  }

  &Sub {
    width: 100%;
    margin: 0 auto;
    padding-top: 18.4rem;
    display: flex;
    justify-content: flex-end;

    @include m.pc {
      max-width: 1460px;
      padding-left: 20px;
    }

    @include m.sp {
      padding-top: 6.4rem;
    }

    &_cont {
      width: 122.2rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4rem;

      @include m.sp {
        width: 95%;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 4.8rem;
      }
    }

    &_text {
      flex: 1;
      font-size: 1.6rem;
      line-height: 2.61;
      letter-spacing: 0.08em;
      transition: translate 1s ease-out, opacity 1s ease-out;
      translate: -30px 0;
      opacity: 0;

      @include m.sp {
        padding-right: 5%;
        font-size: 1.4rem;
        line-height: 2.2;
        text-align: center;
      }

      &Inner {
        font-size: 2.2rem;
        font-weight: bold;
        color: v.$green;

        @include m.sp {
          padding-top: 0.8rem;
          font-size: 1.8rem;
          line-height: 1.8rem;
        }
      }
    }

    &_img {
      transition: translate 1s ease-out 0.1s, opacity 1s ease-out 0.1s;
      translate: -30px 0;
      opacity: 0;

      @include m.pc {
        width: 67rem;
      }
    }

    &.is-animated {
      .p-heroSub {
        &_text,
        &_img {
          translate: 0 0;
          opacity: 1;
        }
      }
    }
  }
}
