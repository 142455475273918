@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-contact {
  padding: 21.6rem 0 16rem;

  @include m.sp {
    padding: 8rem 0 12rem;
  }

  &Intro {
    &_text {
      padding-top: 1.6rem;
    }
  }

  &Required {
    color: v.$red;
  }

  &Cont {
    padding-top: 6.4rem;

    @include m.sp {
      padding-top: 2.4rem;
    }

    &.is-confirm {
      .p-contactForm_head {
        padding: 1.6rem 0;

        @include m.sp {
          padding: 0;
        }
      }
    }
  }

  &Form {
    width: 100%;
    display: flex;
    gap: 4rem;

    @include m.sp {
      flex-flow: column;
      gap: 0.6rem;
    }

    & + & {
      margin-top: 3rem;

      @include m.sp {
        margin-top: 2.4rem;
      }
    }

    &_head {
      padding-top: 3.6rem;

      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        padding-top: 1.8rem;
      }
    }

    &_body {
      @include m.pc {
        width: 77.4rem;
      }

      p {
        padding: 1.6rem 0;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.89;
        letter-spacing: 0.08em;

        @include m.sp {
          padding: 0;
          font-size: 1.4rem;
        }
      }
    }

    &_input {
      width: 100%;
      padding: 1.6rem 3.2rem;
      display: block;
      appearance: none;
      background-color: v.$grayForm;
      border-radius: 10px;

      @include m.sp {
        padding: 1.2rem 1.6rem;
        border-radius: 0.5rem;
      }

      &--textarea {
        height: 260px;
        resize: none;

        @include m.sp {
          height: 16rem;
        }
      }
    }
  }

  &Privacy {
    padding-top: 4rem;

    &_cont {
      width: 100%;
      height: 200px;
      margin-top: 0.8rem;
      padding: 1.6rem;
      border: 1px solid v.$grayBorder;
      background-color: v.$white;
    }

    &_heading {
      @include m.sp {
        font-size: 1.3rem;
      }
    }

    &_scroll {
      width: 100%;
      height: 100%;
      padding-right: 1.2rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 1.2rem;

        @include m.sp {
          width: 0.8rem;
        }
      }
      &::-webkit-scrollbar-track {
        background-color: v.$grayScrollBar;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: v.$green;
        border-radius: 10px;
      }
    }

    &_inner {
      &Box {
        padding-top: 1.6rem;
      }
    }

    &_text {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 0.08em;

      @include m.sp {
        font-size: 1.2rem;
      }
    }
  }

  &Btn {
    padding-top: 5.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12rem;

    @include m.sp {
      gap: 8rem;
      padding-top: 6.4rem;
    }

    &_label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.6rem;

      @include m.pc {
        cursor: pointer;
        transition: opacity v.$anime;

        .p-contactBtn {
          &_submit {
            transition: color v.$anime;
          }
        }

        &:hover {
          .p-contactBtn {
            &_submit {
              color: v.$green;

              & + span {
                &:before {
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      &--back {
      }
    }

    &_submit {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.08em;

      @include m.sp {
        font-size: 1.6rem;
      }

      & + span {
        width: 4rem;
        height: 4rem;
        margin: 0;
        display: block;
        border-radius: 50%;
        background: v.$green;
        position: relative;
        z-index: 1;
        visibility: visible;
        opacity: 1;
        overflow: hidden;

        @include m.pc {
          &:before {
            transition: opacity v.$anime;
          }
        }

        @include m.sp {
          width: 3.2rem;
          height: 3.2rem;
        }

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background: v.$gradGreenBg01;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          animation: none !important;
          translate: -50% -50%;
        }

        &:after {
          content: '';
          width: 1rem;
          height: 1rem;
          display: block;
          border-right: 2px solid v.$white;
          border-bottom: 2px solid v.$white;
          position: absolute;
          top: 50%;
          left: calc(50% - 0.1rem);
          translate: -50% -50%;
          rotate: -45deg;

          @include m.sp {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }

      &--back {
        order: 1;
      }

      &Ico {
        &--back {
          order: 0;
          rotate: -180deg;
        }
      }
    }
  }

  .wpcf7-not-valid-tip {
    padding-top: 0.4rem;
    font-size: 1.2rem;
  }

  .wpcf7-response-output {
    border: none !important;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: #dc3232;
  }

  &Thanks {
    @include m.sp {
      padding-top: 4rem;
    }

    &_text {
      padding-top: 6.4rem;

      @include m.sp {
        padding-top: 4rem;
      }
    }

    &_btn {
      padding-top: 8rem;

      @include m.sp {
        padding-top: 6.4rem;
        display: flex;
        justify-content: center;
      }

      &Item {
        @extend .p-contactBtn_submit;
        display: flex;
        align-items: center;
        gap: 0.8rem;

        &Inner {
          width: 4rem;
          height: 4rem;
          margin: 0;
          display: block;
          border-radius: 50%;
          background-color: v.$green;
          position: relative;
          z-index: 1;
          visibility: visible;
          opacity: 1;

          @include m.sp {
            width: 3.2rem;
            height: 3.2rem;
          }

          &:before {
            display: none;
          }

          &:after {
            content: '';
            width: 1rem;
            height: 1rem;
            display: block;
            border-right: 2px solid v.$white;
            border-bottom: 2px solid v.$white;
            position: absolute;
            top: 50%;
            left: calc(50% - 0.1rem);
            translate: -50% -50%;
            rotate: -45deg;

            @include m.sp {
              width: 0.8rem;
              height: 0.8rem;
            }
          }
        }
      }

      @include m.pc {
        transition: opacity v.$anime;

        .p-contactThanks_btnItem {
          transition: color v.$anime;
        }

        &:hover {
          opacity: 0.7;

          .p-contactThanks_btnItem {
            color: v.$green;
          }
        }
      }
    }
  }
}
