@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-intro {
  display: flex;
  align-items: baseline;
  gap: 3.2rem;

  @include m.sp {
    gap: 1.6rem;
    padding-bottom: 3.2rem;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid v.$green;
    padding-bottom: 2.4rem;

    &:after {
      content: '';
      width: 4.5rem;
      height: 0.2rem;
      background-color: v.$green;
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 2;
    }
  }

  &_heading {
    font-family: v.$mplus;
    font-size: 7.6rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.08em;
    color: v.$green;

    @include m.sp {
      font-size: 3.2rem;
    }

    &--small {
      font-size: 4.8rem;

      @include m.sp {
        font-size: 2.4rem;
      }
    }
  }

  &_text {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.08em;

    @include m.sp {
      font-size: 1.6rem;
    }
  }

  &--border {
    @include m.pc {
      padding-bottom: 3.2rem;
      position: relative;
      z-index: 1;
      border-bottom: 1px solid v.$green;

      &:after {
        content: '';
        width: 4.5rem;
        height: 0.2rem;
        background-color: v.$green;
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 2;
      }
    }
  }

  &--col {
    flex-flow: column;
    align-items: flex-start;
    gap: 1.6rem;

    @include m.sp {
      flex-flow: row;
      align-items: baseline;
    }
  }
}
